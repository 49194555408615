export const utilities = (function ()
{
    this.showLoader = () =>
    {
        const loader = document.querySelector( '#loading-spinner' );
        if ( loader === null )
        {
            return;
        }

        loader.style.display = 'block';
    };

    this.hideLoader = () =>
    {
        const loader = document.querySelector( '#loading-spinner' );
        if ( loader === null )
        {
            return;
        }

        loader.style.display = 'none';
    };

    this.onReady = ( callback ) =>
    {
        // see if DOM is already available
        if ( document.readyState === "complete" || document.readyState === "interactive" )
        {
            // call on next available tick
            setTimeout( callback, 1 );
        }
        else
        {
            document.addEventListener( "DOMContentLoaded", callback );
        }
    };

    return this;
}).apply( {} );

export const tabulatorHelper = (function ()
{
    this.initTabulator = async ( id, tabulatorOptions, appOptions ) =>
    {
        let defaults = [];
        [defaults, appOptions] = this.defaultSettings(
            id,
            tabulatorOptions,
            appOptions
        );

        delete tabulatorOptions.columns;

        const { TabulatorFull } = await import( /* webpackIgnore: true */ '../plugins/tabulator/js/tabulator_esm.min.js' );
        const table = new TabulatorFull(
            id,
            {
                ...defaults,
                ...tabulatorOptions
            }
        );

        return table;
    };

    this.defaultSettings = ( tableId, tabulatorOptions, appOptions = {} ) =>
    {
        tableId = tableId.replace( '#', '' );

        tabulatorOptions = { ...tabulatorOptions }; // Clone the options

        const columns = tabulatorOptions.columns;

        appOptions = {
            ...{
                containerId: tableId,
                persistenceId: tableId,
                selectedPersistenceId: tableId, // ID used for the selected items
                hasHeaderSort: true,
                hasHeaderFilter: true,
            },
            ...appOptions,
        };

        tabulatorOptions.langs = {
            "it": { // Italian language definition
                "data": {
                    "error": "Errore",
                    "loading": "Caricamento in corso",
                },
                "pagination": {
                    "first": "Prima",
                    "first_title": "Prima Pagina",
                    "last": "Ultima",
                    "last_title": "Ultima Pagina",
                    "prev": "Precedente",
                    "prev_title": "Pagina Precedente",
                    "next": "Sucessiva",
                    "next_title": "Pagina Sucessiva",
                    "all": "Tutti",
                    "page_size": 'Quantità',
                    "page_title": "Mostra pagina",//tooltip text for the numeric page button, appears in front of the page number (eg. "Show Page" will result in a tool tip of "Show Page 1" on the page 1 button)
                    "counter": {
                        "showing": "",
                        "of": "di",
                        "rows": "righe",
                        "pages": "pagine",
                    }

                },
            },
            "fr": { // French language definition
                "data": {
                    "error": "Erreur",
                    "loading": "Chargement",
                },
                "pagination": {
                    "first": "Premier",
                    "first_title": "Première Page",
                    "last": "Dernier",
                    "last_title": "Dernière Page",
                    "prev": "Précédent",
                    "prev_title": "Page Précédente",
                    "next": "Suivant",
                    "next_title": "Page Suivante",
                    "all": "Toute",
                },
            },
            "de": { // German language definition
                "data": {
                    "error": "Fehler",
                    "loading": "Laden",
                },
                "pagination": {
                    "first": "Zuerst",
                    "first_title": "Zuerst Seite",
                    "last": "Last",
                    "last_title": "Letzte Seite",
                    "prev": "Zurück",
                    "prev_title": "Zurück Seite",
                    "next": "Nächster",
                    "next_title": "Nächster Seite",
                    "all": "Alle",
                },
            },
        };

        // Add some defaults to the columns
        let lastColumnIndex = null;

        if ( tabulatorOptions.selectable )
        {
            columns.unshift(
                {
                    formatter: "rowSelection",
                    titleFormatter: "rowSelection",
                    hozAlign: "center",
                    headerSort: false,
                    width: 30,
                    minWidth: 30,
                }
            );
        }

        columns.forEach( ( column, index ) =>
        {
            lastColumnIndex = index;

            if ( ! ('field' in column) ) // Just a button column, skip!
            {
                return;
            }

            if ( ! ('responsive' in column) )
            {
                columns[index].responsive = 1;
            }

            if ( appOptions.hasHeaderFilter && ! ('headerFilter' in column) )
            {
                columns[index].headerFilter = true;
            }

            if ( ! ('visible' in column) )
            {
                columns[index].visible = localStorage.getItem( `tabulator-${appOptions.persistenceId}-${column.field}-visible` ) !== '0';
            }

            if ( ! ('print' in column) )
            {
                columns[index].print = true;
            }
/*

            if ( ('editor' in column) && column.editor === 'date' )
            {
                columns[index].editor = TGHelpers.tabulator.dateEditor;
            }

            if ( ('sorter' in column) )
            {
                // We don't have the external library luxon installed to use the bundled functions
                if ( column.sorter === 'datetime' )
                {
                    columns[index].sorter = TGHelpers.tabulator.dateTimeSorter;
                }
                else if ( column.sorter === 'date' )
                {
                    columns[index].sorter = TGHelpers.tabulator.dateSorter;
                }
                else if ( column.sorter === 'time' )
                {
                    columns[index].sorter = TGHelpers.tabulator.timeSorter;
                }
            }
*/
/*
            if ( ('formatter' in column) && column.formatter === 'datetime' )
            {
                // We don't have the external library luxon installed to use the bundled functions
                columns[index].formatter = TGHelpers.tabulator.dateTimeFormatter;
            }*/
        } );

        if ( ! ('height' in tabulatorOptions) || ! ('height' in tabulatorOptions) )
        {
            // tabulatorOptions.maxHeight =  '80vh'; // ALWAYS have a fixed height so the virtual DOM is used
            tabulatorOptions.maxHeight = '80vh';
        }

        return [
            { // Tabulator options
                layout: 'fitColumns', // fitColumns, fitDataStretch, fitDataFill, fitData, fitDataTable
                printAsHtml: true, //enable html table printing
                responsiveLayout: 'collapse',
                resizableColumnFit: true,
                persistence: {
                    sort: true, //persist column sorting
                    filter: true, //persist filter sorting
                    group: true, //persist row grouping
                    // page: true, //persist page
                    page: {
                        size: true, //persist the current page size
                        page: true, //do not persist the current page
                    },
                    columns: false,// [/!*"width", /!*"visible",*!/ "frozen"], //persist columns. Visible creates issues with responsive columns
                },
                selectablePersistence: tabulatorOptions.selectable, // Semi persistence... options aren't selected after setData()
                persistenceID: appOptions.persistenceId,
                columns: columns,
                columnDefaults: {
                    headerTooltip: true
                },
                ...tabulatorOptions
            },
            appOptions
        ];
    };

    return this;
}).apply( {} );