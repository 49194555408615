export default function ( bind )
{
    return _.merge( {
            remoteSettings: {
                url: 'autocomplete.php?q=%QUERY', // Set the URL to a real endpoint => result should be an array of objects
                wildcard: '%QUERY',
                options: {}, // Fetch options https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
            },
            placeholder: 'Select an option',
            emptyOptionsMessage: 'No results match your search.',
            loadingOptionsMessage: 'Loading...',

            value: {},
            options: [],
            searchValue: '',
            isOpen: false,
            isLoading: false,
            focusedOptionIndex: null,
            _remoteRequestAbortController: null,

            closeListbox: function ()
            {
                this.isOpen = false;
                this.focusedOptionIndex = null;
                this.searchValue = '';
            },
            focusNextOption: function ()
            {
                if ( this.focusedOptionIndex === null )
                {
                    return this.focusedOptionIndex = Object.keys( this.options ).length - 1;
                }

                if ( this.focusedOptionIndex + 1 >= Object.keys( this.options ).length )
                {
                    return;
                }

                this.focusedOptionIndex++;

                this.$refs.listbox.children[this.focusedOptionIndex].scrollIntoView( {
                    block: "center",
                } );
            },
            focusPreviousOption: function ()
            {
                if ( this.focusedOptionIndex === null )
                {
                    return this.focusedOptionIndex = 0;
                }

                if ( this.focusedOptionIndex <= 0 )
                {
                    return;
                }

                this.focusedOptionIndex--;

                this.$refs.listbox.children[this.focusedOptionIndex].scrollIntoView( {
                    block: "center",
                } );
            },
            getSearchValue: function ()
            {
                return this.$refs.search.value;
            },
            showTypeahead: async function ()
            {
                const newSearchValue = this.getSearchValue();

                // Same value as last search... skip
                if ( this.searchValue === newSearchValue )
                {
                    return;
                }

                this.searchValue = newSearchValue;

                if ( ! this.isOpen )
                {
                    this.toggleListboxVisibility();
                }

                this.options = [];
                this.isLoading = true;

                try
                {
                    this.options = this.parseRemoteData(
                        await this.loadRemoteData()
                    );

                    this.isLoading = false;
                }
                catch ( e )
                {
                    if ( ! e.message.search( 'aborted' ) )
                    {
                        throw e;
                    }
                }
            },
            loadRemoteData: async function ()
            {
                if ( this._remoteRequestAbortController !== null )
                {
                    this._remoteRequestAbortController.abort();
                }

                if ( this.searchValue.length === 0 )
                {
                    return [];
                }

                const url = this.remoteSettings.url.replace( this.remoteSettings.wildcard, this.searchValue );

                this._remoteRequestAbortController = new AbortController();

                const response = await fetch(
                    url,
                    {
                        ...this.remoteSettings.options,
                        signal: this._remoteRequestAbortController.signal
                    }
                );

                this._remoteRequestAbortController = null;

                if ( ! response.ok )
                {
                    throw "HTTP-Error: " + response.status;
                }

                return await response.json();
            },
            parseRemoteData: function ( data )
            {
                return data;
            },
            selectOption: function ()
            {
                if ( ! this.isOpen )
                {
                    return this.toggleListboxVisibility();
                }

                this.value = this.options[this.focusedOptionIndex];

                this.closeListbox();
            },
            toggleListboxVisibility: function ()
            {
                if ( this.isOpen )
                {
                    return this.closeListbox();
                }

                this.focusedOptionIndex = Object.keys( this.options ).indexOf( this.value );

                if ( this.focusedOptionIndex < 0 )
                {
                    this.focusedOptionIndex = 0;
                }

                this.isOpen = true;
            },
        },
        bind
    );
}