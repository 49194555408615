import './bootstrap';

import Alpine from 'alpinejs';
import {utilities} from "./core";
import AlpineAutocomplete from "./apline/autocomplete";

window.Alpine = Alpine;

window.App = (function ()
{
    this.utilities = utilities;

    this.autocomplete = AlpineAutocomplete;

    this.utilities.onReady( () =>
    {
        document.addEventListener( 'submit', this.utilities.showLoader );

        this.utilities.hideLoader();
        Alpine.start();
    } );

    return this;
}).apply({});